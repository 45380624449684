import { StytchUIClient } from "@stytch/vanilla-js";
import { StytchB2BUIClient } from "@stytch/vanilla-js/dist/b2b";
import React, { createContext, ReactNode, useContext } from "react";

type StytchContextValue =
  | { vividlyStytchClient: StytchUIClient; type: "regular" }
  | { vividlyStytchClient: StytchB2BUIClient; type: "b2b" }
  | null;

const CombinedStytchContext = createContext<StytchContextValue | null>(null);

interface CombinedStytchProviderProps {
  vividlyStytchClient: StytchUIClient | StytchB2BUIClient;
  type: "regular" | "b2b";
  children: ReactNode;
}

/**
 * Provides a unified context for Stytch clients, allowing seamless use of either `StytchUIClient` (regular)
 * or `StytchB2BUIClient` (B2B) without worrying about mismatched client types or exceptions.
 */
export const CombinedStytchProvider: React.FC<CombinedStytchProviderProps> = ({
  vividlyStytchClient,
  type,
  children,
}) => {
  let value: StytchContextValue;

  if (type === "regular") {
    value = {
      vividlyStytchClient: vividlyStytchClient as StytchUIClient,
      type,
    };
  } else if (type === "b2b") {
    value = {
      vividlyStytchClient: vividlyStytchClient as StytchB2BUIClient,
      type,
    };
  } else {
    value = null;
  }

  return (
    <CombinedStytchContext.Provider value={value}>
      {children}
    </CombinedStytchContext.Provider>
  );
};

export const useCombinedStytchClient = () => {
  const context = useContext(CombinedStytchContext);
  if (!context) {
    throw new Error(
      "useCombinedStytchClient must be used within a CombinedStytchProvider"
    );
  }
  return context;
};
