import {
  error,
  miscellaneousColors,
  neutral,
  neutralVariant,
  primary,
  secondary,
  success,
  tertiary,
  warning,
} from "./colors";

export const muiPaletteLight = {
  primary: {
    main: primary[30],
    light: "#135B6F",
    dark: "#003544",
    contrastText: neutral.vividlyWhite,
    container: primary[90],
    onContainer: primary[10],
    inverse: primary[80],
    onhover: "#135B6F",
  },
  secondary: {
    main: secondary[30],
    light: "#8C41AE",
    dark: "#4E0C6B",
    contrastText: neutral.vividlyWhite,
    container: secondary[90],
    onContainer: secondary[10],
  },
  tertiary: {
    main: tertiary[30],
    light: "#98339E",
    dark: "#58005D",
    contrastText: neutral.vividlyWhite,
    container: tertiary[90],
    onContainer: tertiary[10],
  },
  tonal: {
    // Secondary container color
    main: secondary[90],
    light: "#FADFFF",
    dark: "#E9C7F0",
    contrastText: secondary[10],
  },
  error: {
    main: error[40],
    light: "#A83337",
    dark: "#660004",
    contrastText: neutral.vividlyWhite,
    container: error[95],
    onContainer: error[20],
  },
  warning: {
    main: warning[30],
    light: "#965733",
    dark: "#561F00",
    contrastText: neutral.vividlyWhite,
    container: warning[90],
    onContainer: warning[10],
  },
  info: {
    main: "#0288d1",
    light: "#03a9f4",
    dark: "#01579b",
    contrastText: neutral.vividlyWhite,
    container: "#F0E82B",
    onContainer: "#1E1C00",
    favorite: "#FFD54F",
  },
  success: {
    main: success[40],
    light: "#578853",
    dark: "#1F4A1C",
    contrastText: neutral.vividlyWhite,
    container: success[90],
    onContainer: success[10],
  },
  background: {
    default: neutral[99],
    surfaceVariant: miscellaneousColors.popoverGray,
    onBackground: neutral[10],
    onSurfaceVariant: neutralVariant[30],
    inverseSurface: neutral[20],
    inverseOnSurface: neutral[95],
    outline: neutralVariant[50],
    surfaceContainer: neutralVariant.bright,
  },
  vividlyGradients: {
    gradient1:
      "linear-gradient(270deg, #F7F003 2.38%, #FBB20A 17.16%, #FF8110 30.8%, #FF6FB2 53.55%, #FF66FF 64.92%, #AA80FF 76.29%, #00B4FF 97.89%)",
    gradient2:
      "linear-gradient(72.28deg, #F7F003 4.45%, #FAC80B 15.27%, #FF8019 32.52%, #FF7F1F 34.33%, #FF7D31 36.81%, #FF7A4E 39.69%, #FF7955 40.26%, #FF785F 41.35%, #FF7578 43.15%, #FF7389 44.13%, #FF6FB4 47.85%, #FF67FC 56.36%, #D67DFF 66.09%, #8F94F4 77.22%, #809AF5 79.54%, #57AAF7 84%, #17C3FC 90.14%, #00CCFD 92.18%, #07CAEA 102.69%)",
  },
  readOnly: {
    surface1: "#F0EDF1",
    surface2: "#F0EDF1",
    surface3: "#F0EDF1",
    surface4: "#F0EDF1",
    surface5: "#F0EDF1",
  },
};

export const muiPaletteDark = {
  primary: {
    main: primary[80],
    light: "#73DDFF",
    dark: "#3895B2",
    contrastText: primary[20],
    container: primary[30],
    onContainer: primary[90],
    inverse: primary[40],
    onhover: "#73DDFF",
  },
  secondary: {
    main: secondary[80],
    light: "#EFC1FF",
    dark: "#A47CB2",
    contrastText: secondary[20],
    container: secondary[30],
    onContainer: secondary[90],
  },
  tertiary: {
    main: tertiary[80],
    light: "#FFBAFB",
    dark: "#B276AF",
    contrastText: tertiary[20],
    container: tertiary[30],
    onContainer: tertiary[90],
  },
  tonal: {
    main: secondary[30],
    light: "#8C41AE",
    dark: "#4E0C6B",
    contrastText: secondary[90],
    pale: secondary[90],
  },
  error: {
    main: error[90],
    light: "#FFC3BA",
    dark: "#B27D76",
    contrastText: error[30],
    container: error[40],
    onContainer: error[95],
  },
  warning: {
    main: warning[80],
    light: "#FFC3A7",
    dark: "#B27E66",
    contrastText: warning[20],
    container: warning[30],
    onContainer: warning[90],
  },
  info: {
    main: "#0288d1",
    light: "#03a9f4",
    dark: "#01579b",
    contrastText: "#353200",
    container: "#4C4800",
    onContainer: "#F0E82B",
  },
  success: {
    main: success[80],
    light: "#A9DF9E",
    dark: "#67965D",
    contrastText: success[20],
    container: success[30],
    onContainer: success[90],
  },
  background: {
    default: neutral[10],
    paper: "#262626",
    surfaceVariant: neutralVariant[30],
    onBackground: neutral[90],
    onSurfaceVariant: neutralVariant[80],
    inverseSurface: neutral[90],
    inverseOnSurface: neutral[10],
    outline: neutralVariant[60],
  },
  vividlyGradients: {
    gradient1:
      "linear-gradient(270deg, #F7F003 2.38%, #FBB20A 17.16%, #FF8110 30.8%, #FF6FB2 53.55%, #FF66FF 64.92%, #AA80FF 76.29%, #00B4FF 97.89%)",
    gradient2:
      "linear-gradient(72.28deg, #F7F003 4.45%, #FAC80B 15.27%, #FF8019 32.52%, #FF7F1F 34.33%, #FF7D31 36.81%, #FF7A4E 39.69%, #FF7955 40.26%, #FF785F 41.35%, #FF7578 43.15%, #FF7389 44.13%, #FF6FB4 47.85%, #FF67FC 56.36%, #D67DFF 66.09%, #8F94F4 77.22%, #809AF5 79.54%, #57AAF7 84%, #17C3FC 90.14%, #00CCFD 92.18%, #07CAEA 102.69%)",
  },
  readOnly: {
    surface1:
      "linear-gradient(0deg, rgba(81, 213, 255, 0.05), rgba(81, 213, 255, 0.05)), #1A1B1F",
    surface2:
      "linear-gradient(0deg, rgba(81, 213, 255, 0.08), rgba(81, 213, 255, 0.08)), #1A1B1F",
    surface3:
      "linear-gradient(0deg, rgba(81, 213, 255, 0.11), rgba(81, 213, 255, 0.11)), #1A1B1F",
    surface4:
      "linear-gradient(0deg, rgba(81, 213, 255, 0.12), rgba(81, 213, 255, 0.12)), #1A1B1F",
    surface5:
      "linear-gradient(0deg, rgba(81, 213, 255, 0.14), rgba(81, 213, 255, 0.14)), #1A1B1F",
  },
};

// DATA VISUALIZATION COLORS

export const graphPalette = {
  yellow90: "#F0E82B",
  blue80: primary[80],
  pink80: "#FFA9FA",
  purple80: secondary[80],
  orange80: warning[80],
  red80: "#FF897A",
  green80: success[80],
  yellow80: "#D3CB00",
  blue70: "#00BAE8",
  pink70: "#FF72FF",
  purple70: "#DD89FF",
  orange70: "#FF8C51",
  red70: "#FF5449",
  green70: "#79BB6D",
  grey60: "#909094",
};
