const projectId = "cresicor-dev";
const stytchPublicToken =
  "public-token-test-8798b5ed-ad84-4226-91e6-cc2b76ce6b28";
const stytchB2BPublicToken =
  "public-token-test-5cac9a1e-8522-4341-a2c4-7f94ed3915b8";
const celloProductId = "stage-www.govividly.com";
const celloScriptUrl = "https://assets.sandbox.cello.so/app/latest/cello.js";

export default {
  projectId,
  stytchPublicToken,
  stytchB2BPublicToken,
  celloProductId,
  celloScriptUrl,
};
