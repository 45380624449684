export const SSO_PARAM = "sso";
const ORGANIZATION_SSO_SLUG = "organizationSSOSlug";

// TODO: Maybe use some other method of detecting SSO login
export const isSSOLogin = (): boolean =>
  window.location.href.includes(SSO_PARAM) ||
  window.location.href.includes("authenticate-b2b");

export const setOrganizationSSOSlug = (
  organizationSlug: string | null
): void => {
  if (organizationSlug === null) {
    localStorage.removeItem(ORGANIZATION_SSO_SLUG);
  } else {
    localStorage.setItem(ORGANIZATION_SSO_SLUG, organizationSlug);
  }
};

export const getOrganizationSSOSlug = (): string | null =>
  localStorage.getItem(ORGANIZATION_SSO_SLUG);

export const ssoRedirect = () => {
  const organizationSlug = getOrganizationSSOSlug();
  if (organizationSlug) {
    setOrganizationSSOSlug(null); // this will be reset on the next login
    window.location.href = `${window.location.origin}/sso/${organizationSlug}`;
  }
};
