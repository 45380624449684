import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { StytchProvider } from "@stytch/react";
import { StytchB2BProvider } from "@stytch/react/b2b";
import { StytchUIClient } from "@stytch/vanilla-js";
import { StytchB2BUIClient } from "@stytch/vanilla-js/b2b";

import { captureConsoleIntegration } from "@sentry/integrations";
import SentryFullStory from "@sentry/fullstory";
import * as Sentry from "@sentry/react";
import * as FullStory from "@fullstory/browser";

import { SnackbarProvider } from "notistack";
import CssBaseline from "@mui/material/CssBaseline";
import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import { Settings as LuxonSettings } from "luxon";

// Vividly imports start here
import { sentryDSN, stytch as stytchConfig } from "configs";
import { Provider as UrqlProvider } from "urql";
import theme from "helpers/mui-theme";
import { ErrorBoundary } from "react-error-boundary";
import { client } from "helpers/graphql/clientSetup";
import { DEPLOYMENT_ENVIRONMENT } from "helpers/enums";
import "styles";
import DrmSnackbar from "ui-library/DrmSnackbar";
import { getOrganizationSSOSlug, isSSOLogin } from "helpers/authUtils";
import { CombinedStytchProvider } from "hooks/useCombinedStychProvider";

const Authenticate = React.lazy(
  () /* Authenticate */ => import("components/Authenticate")
);
const AuthenticateB2B = React.lazy(
  () /* AuthenticateB2B */ => import("components/AuthenticateB2B")
);
const AuthCheck = React.lazy(
  () /* AuthCheck */ => import("components/AuthCheck")
);

const Error = React.lazy(() /* Error */ => import("components/Error"));
const sentryEnvironments = [
  DEPLOYMENT_ENVIRONMENT.BETA.valueOf(),
  DEPLOYMENT_ENVIRONMENT.PROD.valueOf(),
];

// Fullstory init
const isProduction = window.location.origin.includes("portal.govividly.com");
FullStory.init({ orgId: "o-1D6G72-na1", devMode: !isProduction });

// Sentry init
Sentry.init({
  dsn: sentryDSN,
  release: import.meta.env.VITE_SENTRY_RELEASE_NAME,
  environment: import.meta.env.VITE_ENVIRONMENT,
  integrations: [
    Sentry.browserTracingIntegration(),
    captureConsoleIntegration({ levels: ["error"] }),
    new SentryFullStory("cresicor-ai", { client: FullStory }) as never, // casting as never since the types aren't aligning
  ],
  enabled: sentryEnvironments.includes(import.meta.env.VITE_ENVIRONMENT), // only enable sentry in beta and prod
  sampleRate: 1.0,
  tracesSampleRate: 0.0015,
});

function ErrorFallback(localProps: { error?: Error | String }) {
  if (localProps?.error) {
    Sentry.captureException(localProps.error);
  }
  return <Error />;
}

// Stytch init
const stytchB2BClient = new StytchB2BUIClient(stytchConfig.publicTokenB2B);
const stytchClient = new StytchUIClient(stytchConfig.publicToken);
const ssoUser = getOrganizationSSOSlug() || isSSOLogin();

// luxon settings
LuxonSettings.defaultLocale = "en-US";
// [COR-897] Disable console log and warn on production
if (isProduction) {
  // eslint-disable-next-line no-console
  console.log = () => {};
  console.warn = () => {};
  console.info = () => {};
}

const routes = (
  <Routes>
    <Route path="/authenticate" element={<Authenticate />} />
    <Route path="/authenticate-b2b" element={<AuthenticateB2B />} />
    <Route path="/*" element={<AuthCheck />} />
  </Routes>
);

// React init
const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <UrqlProvider value={client}>
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            Components={{
              drm: DrmSnackbar,
            }}
          >
            <DndProvider backend={HTML5Backend}>
              <BrowserRouter>
                <CombinedStytchProvider
                  type={ssoUser ? "b2b" : "regular"}
                  vividlyStytchClient={ssoUser ? stytchB2BClient : stytchClient}
                >
                  {ssoUser ? (
                    <StytchB2BProvider stytch={stytchB2BClient}>
                      {routes}
                    </StytchB2BProvider>
                  ) : (
                    <StytchProvider stytch={stytchClient}>
                      {routes}
                    </StytchProvider>
                  )}
                </CombinedStytchProvider>
              </BrowserRouter>
            </DndProvider>
          </SnackbarProvider>
        </MuiThemeProvider>
      </StyledEngineProvider>
    </UrqlProvider>
  </ErrorBoundary>
);
